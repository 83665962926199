import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import Adminapi from "../../services/AdminApi";

export const adminLogin = createAsyncThunk("Login", async (details) => {
  try {
    const { data } = await Adminapi.post("/admin/login", details);
    console.log(data);

    if (data?.status == 200) {
      sessionStorage.setItem("token", data?.data?.token);
      toast.success(data?.message);
      setTimeout(() => {
        toast.dismiss();
        window.location.href = "/Dashboard";
      }, 1500);
    } else {
      toast.error(data?.message);
    }
    return data;
  } catch (error) {
    toast.error("Something went wrong");
  }
});
