import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";

export default function Pagination({ totalstuff,limit,setpages,search ,pagenumber }) {
  // const handlePageClick = (event) => {
  //   // paginationevent(event.selected + 1);
  // };

  console.log(totalstuff,"totalstuff")

  return (
    <div className="pagination-wrapper next-btn-fix set-pagi-btm">
      <ReactPaginate
        breakLabel="..."
        nextLabel={"next"}
        onPageChange={(e) => setpages(e?.selected)}
        pageRangeDisplayed={totalstuff}
        pageCount={search? 0 :totalstuff}
        previousLabel={"prev"}
        renderOnZeroPageCount={null}
        initialPage={pagenumber ? pagenumber : 0}
      />
    </div>
  );
}
