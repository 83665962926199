import { createSlice } from "@reduxjs/toolkit";
import { NotificationListing } from "../Actions/adminAction";

export const notificationSlice = createSlice({
    name: "notificationSlice",
    initialState: {
        alldata: "",
        loading: false,
        error: null,
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(NotificationListing.pending, (state) => {
                state.loading = true; 
                state.error = null;  
            })
            .addCase(NotificationListing.fulfilled, (state, action) => {
                state.loading = false; 
                state.alldata = action.payload.data; 
            })
            .addCase(NotificationListing.rejected, (state, action) => {
                state.loading = false; 
                state.error = action.error.message; 
            });
    },
});

export default notificationSlice.reducer;

